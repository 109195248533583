import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";

export default ({
  data: {
    allFile: { nodes },
  },
}: PageProps<IData>) => {
  return (
    <Layout wrapperClass="blog-index-page">
      <Seo title="PDF Blog" />
      <div className="container">
        <div className="col-12">
          <h1 className="h2 mb-5">Blog</h1>
          {nodes.map(
            ({
              childMdx: {
                id,
                slug,
                excerpt,
                frontmatter: { title, image, image_alt },
              },
            }) => {
              const imageData = getImage(image);

              return (
                <article className="row blog-list-item" key={id}>
                  <div className="col-md-5 mb-5">
                    <Link to={slug}>
                      <GatsbyImage image={imageData} alt={image_alt ?? ""} />
                    </Link>
                  </div>
                  <div className="col-md-7">
                    <h2>
                      <Link to={slug}>{title}</Link>
                    </h2>
                    {excerpt}
                    <p>
                      <Link to={slug}>Read more</Link>
                    </p>
                  </div>
                </article>
              );
            }
          )}
        </div>
      </div>
    </Layout>
  );
};

interface IData {
  allFile: {
    nodes: {
      childMdx: {
        id: string;
        slug: string;
        excerpt: string;
        frontmatter: {
          title: string;
          image: ImageDataLike;
          image_alt: string;
        };
      };
    }[];
  };
}

export const query = graphql`
  query {
    allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        childMdx: { id: { ne: null } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            image_alt
          }
          id
          slug
          excerpt
        }
      }
    }
  }
`;
